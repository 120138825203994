import React, {useState, useEffect} from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as Services from "../services"
import {navigate} from "gatsby";

function IndexPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [process, setProcess] = useState(false);
    const [auth, setAuth] = useState(null)

    useEffect(() => {
        setAuth(localStorage.getItem('auth'));
        console.log('entra', auth);
        if (auth !== null) {
            navigate('/dashboard');
        }
    }, [])


    const loginAction = (e) => {
        e.preventDefault();
        setProcess(!process);
        Services.login(email, password).then((response) => {
            setProcess(false);
            if (response.user) {
                navigate('/dashboard');
            }
            console.log(response);
        }).catch((err) => {
            setProcess(false);
            console.log(err);
        })
    }
    return(
        <Layout>
            <div className="login">
                <div className="container mx-auto">
                    <div className="p-4 py-12">
                        <svg xmlns="http://www.w3.org/2000/svg" width="180" viewBox="0 0 129.842 56.028" className="mx-auto mb-6">
                            <g id="Grupo_680" data-name="Grupo 680" transform="translate(-79.559 0)">
                                <path id="Trazado_1206" data-name="Trazado 1206" d="M268.557,1.587a11.285,11.285,0,1,0,8.5,18.7v1.206a8.5,8.5,0,0,1-15.214,5.2,1.395,1.395,0,0,0-2.2,1.709,11.286,11.286,0,0,0,20.208-6.91V12.382a1.408,1.408,0,0,0-.03-.286A11.3,11.3,0,0,0,268.557,1.587m0,19.781a8.5,8.5,0,1,1,8.5-8.5,8.505,8.505,0,0,1-8.5,8.5" transform="translate(-177.713 -1.097)" fill="#a6d66b"/>
                                <path id="Trazado_1207" data-name="Trazado 1207" d="M390.747,1.587a11.3,11.3,0,0,0-11.278,10.894c0,.039-.006.079-.007.119s0,.056,0,.084c0,.062,0,.125,0,.187a11.285,11.285,0,0,0,19.556,7.677,1.395,1.395,0,1,0-2.044-1.9,8.5,8.5,0,0,1-14.643-4.619l18.289.234a1.376,1.376,0,0,0,1-.4,1.4,1.4,0,0,0,.415-.992A11.3,11.3,0,0,0,390.747,1.587m-8.339,9.656a8.5,8.5,0,0,1,16.717.214Z" transform="translate(-262.117 -1.097)" fill="#a6d66b"/>
                                <path id="Trazado_1208" data-name="Trazado 1208" d="M469.474,1.587A11.3,11.3,0,0,0,458.2,12.481c0,.039-.006.079-.006.119s0,.056,0,.084c0,.062,0,.125,0,.187a11.285,11.285,0,0,0,19.556,7.677,1.395,1.395,0,0,0-2.044-1.9,8.5,8.5,0,0,1-14.643-4.619l18.289.234a1.368,1.368,0,0,0,1-.4,1.4,1.4,0,0,0,.415-.992A11.3,11.3,0,0,0,469.474,1.587m-8.339,9.656a8.5,8.5,0,0,1,16.716.214Z" transform="translate(-316.498 -1.097)" fill="#a6d66b"/>
                                <path id="Trazado_1209" data-name="Trazado 1209" d="M356.059,1.4A1.4,1.4,0,0,0,354.664,0a11.3,11.3,0,0,0-11.285,11.285v10.38a1.395,1.395,0,1,0,2.79,0V11.288a8.5,8.5,0,0,1,8.5-8.5A1.4,1.4,0,0,0,356.059,1.4" transform="translate(-237.192 -0.003)" fill="#a6d66b"/>
                                <path id="Trazado_1210" data-name="Trazado 1210" d="M547.05.767a8.505,8.505,0,0,0-8.5,8.5V22.2a1.395,1.395,0,1,0,2.789,0V9.262a5.706,5.706,0,0,1,11.412,0V22.2a1.395,1.395,0,1,0,2.789,0V9.262a8.505,8.505,0,0,0-8.5-8.5" transform="translate(-372.012 -0.53)" fill="#a6d66b"/>
                                <path id="Trazado_1211" data-name="Trazado 1211" d="M554.151,80.313a1.394,1.394,0,0,0-1.395,1.395V94.641a5.706,5.706,0,1,1-11.412,0V81.708a1.395,1.395,0,0,0-2.789,0V94.641a8.5,8.5,0,1,0,16.991,0V81.708a1.4,1.4,0,0,0-1.394-1.395" transform="translate(-372.012 -55.478)" fill="#a6d66b"/>
                                <path id="Trazado_1212" data-name="Trazado 1212" d="M614.058,79.493a11.552,11.552,0,0,0-11.539,11.539v18.513a1.395,1.395,0,0,0,2.79,0v-11a11.535,11.535,0,1,0,8.749-19.049m0,20.288a8.749,8.749,0,1,1,8.749-8.749,8.759,8.759,0,0,1-8.749,8.749" transform="translate(-416.196 -54.911)" fill="#a6d66b"/>
                                <path id="Trazado_1213" data-name="Trazado 1213" d="M554.718,52.074a10.478,10.478,0,0,0-1.268-2.79,14.611,14.611,0,0,0-1.014-1.268,5.545,5.545,0,0,0-.761,2.283,7.56,7.56,0,0,0,1.014,4.311,9.158,9.158,0,0,0,2.283,2.282c.507.507.507.661.507.661v7.835a.38.38,0,1,0,.761,0V59.709c.254-.535,1.268-.789,1.268-.789a4.35,4.35,0,0,0,1.521-1.015,3.1,3.1,0,0,0,1.014-2.029v-.254a4.981,4.981,0,0,0-2.282,1.014,4.311,4.311,0,0,0-1.522,2.536V51.112a3.774,3.774,0,0,1,.507-1.828c.507-.761,2.282-1.775,2.789-2.79a7.538,7.538,0,0,0,1.014-4.818c-.253-2.536-.507-3.043-.761-3.55s-.507-.76-1.014-.253-3.043,4.311-3.3,6.593a73.582,73.582,0,0,0,0,7.481v3.774a29.842,29.842,0,0,0-.761-3.647" transform="translate(-381.075 -25.97)" fill="#a6d66b"/>
                            </g>
                        </svg>

                        <h1 className="font-greenup-titles font-bold text-2xl text-green-gu text-center">
                            Bienvenido de nuevo
                        </h1>
                        <h3 className="text-center text-green-light-gu">Iniciar sesión</h3>
                        <form className="mt-6 mx-auto lg:max-w-sm" method="post" onSubmit={(e) => loginAction(e)}>
                            <div className="mb-4">
                                <label className="block text-green-gu text-sm font-bold mb-2" htmlFor="username">
                                    Correo Electrónico
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    required
                                    placeholder="hola@ejemplo.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    readOnly={process}
                                />
                            </div>
                            <div className="mb-6">
                                <label className="block text-green-gu text-sm font-bold mb-2" htmlFor="password">
                                    Contraseña
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    readOnly={process}
                                />
                            </div>
                            <div className="mb-4">
                                <button type="submit" className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu" disabled={process}>
                                    {process && (
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                    stroke-width="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>)}  {!process ? 'Ingresar ahora': 'Enviando'}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </Layout>
    )

}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
